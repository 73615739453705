import React from 'react';
import Writings from './Writings';
import Subscribe from './Subscribe';
import Work from './Work';

const Content = () => {
  return (
    <div className='flex gap-12 pt-20 pb-24 justify-between'>
      <div>
        <Writings />
      </div>
      <div className='flex flex-col gap-12'>
        <Subscribe />
        <Work />
      </div>
    </div>
  );
};

export default Content;
