import React from 'react';
import UsesView from './UsesView';
import { Wrapper } from '../../components/modules/Wrapper';

export const UsesContainer = () => {
  return (
    <Wrapper>
      <UsesView />
    </Wrapper>
  );
};
