import React from 'react';
import ErrorView from './ErrorView';

export const ErrorContainer = () => {
  return (
    <div>
      <ErrorView />
    </div>
  );
};
