const SVG_PATH = '/assets/svg';
export const sun = `${SVG_PATH}/sun.svg`;
export const moon = `${SVG_PATH}/moon.svg`;
export const github = `${SVG_PATH}/github.svg`;
export const linkedin = `${SVG_PATH}/linkedin.svg`;
export const twitter = `${SVG_PATH}/twitter.svg`;
export const message = `${SVG_PATH}/message.svg`;
export const briefcase = `${SVG_PATH}/briefcase.svg`;
export const chevronright = `${SVG_PATH}/chevronright.svg`;
export const link = `${SVG_PATH}/link.svg`;
