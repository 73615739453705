import React from 'react';
import Hero from './Hero';
import Links from './Links';

const Articles = () => {
  return (
    <div>
      <Hero />
      <Links />
    </div>
  );
};

export default Articles;
