import React from 'react';

const Hero = () => {
  return (
    <div className='w-[60%]'>
      <h2 className='text-5xl font-bold tracking-tight text-zinc-100 leading-[60px] mt-6'>
        Things I’ve made trying to put my dent in the universe.
      </h2>
      <p className='mt-6 text-base text-zinc-400 leading-7'>
        I’ve worked on tons of little projects over the years but these are the
        ones that I’m most proud of. Many of them are open-source, so if you see
        something that piques your interest, check out the code and contribute
        if you have ideas for how it can be improved.
      </p>
    </div>
  );
};

export default Hero;
