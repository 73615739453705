const Hero = () => {
  return (
    <div className='text-white w-[60%]'>
      <h2 className='text-5xl font-bold tracking-tight text-zinc-100 leading-[60px] mt-6'>
        Writing on software design, company building, and the aerospace
        industry.
      </h2>
      <p className='mt-6 text-base text-zinc-400 leading-7'>
        All of my long-form thoughts on programming, leadership, product design,
        and more, collected in chronological order.
      </p>
    </div>
  );
};

export default Hero;
