import React from 'react';
import { chevronright } from '../../../assets/icons';

const articles = [
  {
    id: 1,
    date: 'September 2, 2022',
    title: 'Getting a Design System For a Multiplenary Future',
    introduction:
      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ad sed, magni blanditiis, facere praesentium quo reiciendis numquam deserunt soluta fugit tenetur consectetur aut consequuntur ex. Consequuntur natus possimus optio labore.',
    links: '',
  },
  {
    id: 2,
    date: 'September 2, 2022',
    title: 'Introducing Animaginary: High Performance Web Animators',
    introduction:
      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ad sed, magni blanditiis, facere praesentium quo reiciendis numquam deserunt soluta fugit tenetur consectetur aut consequuntur ex. Consequuntur natus possimus optio labore.',
    links: '',
  },
  {
    id: 3,
    date: 'September 2, 2022',
    title: 'Rewriting the CosmosOS feature in Rust',
    introduction:
      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ad sed, magni blanditiis, facere praesentium quo reiciendis numquam deserunt soluta fugit tenetur consectetur aut consequuntur ex. Consequuntur natus possimus optio labore.',
    links: '',
  },
];

const Links = () => {
  return (
    <div className='flex flex-col gap-12 w-[800px] border-l-zinc-400 border-l-[0.5px] pl-12 mt-20 mb-12'>
      {articles.map((item) => (
        <div
          key={item.id}
          className='flex gap-4'
        >
          <div>
            <p className='text-sm text-zinc-300 w-[200px] mt-12'>{item.date}</p>
          </div>
          <div className='hover:border-1 hover:border-zinc hover:rounded-2xl hover:bg-zinc-800 p-4 cursor-pointer w-[600px]'>
            <p className='text-zinc-100 font-semibold text-base mt-6'>
              {item.title}
            </p>
            <p className='text-sm text-zinc-400 mt-4 leading-7'>
              {item.introduction}
            </p>
            <div className='mt-6'>
              <a
                href={item.link}
                className='text-teal text-sm flex items-center gap-4'
              >
                Read article <img src={chevronright} alt='' />
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Links;
