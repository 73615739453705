import React from 'react';
import Profile from './Profile';
import Socials from './Socials';

const About = () => {
  return (
    <div className='flex justify-between gap-12 pt-20'>
      <Profile />
      <Socials />
    </div>
  );
};

export default About;
