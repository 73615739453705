import React from 'react';
import { Projects } from '../../components/layout/Projects';

const ProjectsView = () => {
  return (
    <div>
      <Projects />
    </div>
  );
};

export default ProjectsView;
