import React from 'react';
import { github, linkedin, twitter } from '../../../assets/icons';
import { avatar } from '../../../assets/images';

const socialIcons = [
  { id: 1, url: 'https://github.com/dreiOX', icon: github },
  { id: 2, url: 'https://linkedin.com/in/andrew-ogbodo', icon: linkedin },
  { id: 3, url: 'https://twitter.com/dreicode', icon: twitter },
];

const Hero = () => {
  return (
    <div className='text-white w-[60%]'>
      <img src={avatar} alt='avatar' className='h-20 w-20 rounded-full' />
      <h2 className='text-5xl font-bold tracking-tight text-zinc-100 leading-[60px] mt-6'>
        Frontend Developer, founder, and amateur astronaut.
      </h2>
      <p className='mt-6 text-base text-zinc-400 leading-7'>
        I'm Andrew, a Passionate Creator of Engaging Web Experiences. With a
        blend of artistry and technical prowess, I design and code beautiful
        websites that deliver seamless interactions. Join me on a journey where
        innovation meets elegance, as I shape the digital world through
        captivating software development.
      </p>
      <div className='flex items-center gap-6 mt-8'>
        {socialIcons.map((icon) => (
          <a href={icon.url} key={icon.id}>
            <img src={icon.icon} alt='' />
          </a>
        ))}
      </div>
    </div>
  );
};

export default Hero;
