import React from 'react';

const Profile = () => {
  return (
    <div className='w-[55%]'>
      <h2 className='text-5xl font-bold tracking-tight text-zinc-100 leading-[60px] mt-6'>
        I’m Andrew Ogbodo. I live in Lagos, where I build the future.
      </h2>
      <div className='mt-6 text-base text-zinc-400 leading-7 flex flex-col gap-12'>
        <p>
          Unleashing the Magic of Code, I'm Andrew, a Frontend Developer
          Extraordinaire. With an unwavering passion for all things web, I
          embark on a journey to craft captivating digital experiences that
          captivate, engage, and inspire.
        </p>
        <p>
          Combining artistic vision with pixel-perfect precision, I bring ideas
          to life through the harmonious fusion of design and technology. Armed
          with a keen eye for aesthetics and a deep understanding of
          user-centered principles, I create immersive websites that
          effortlessly blend form and function.
        </p>
        <p>
          With every line of code I write, I strive to push the boundaries of
          what's possible, delivering seamless interactions, sleek animations,
          and intuitive interfaces. I believe in the power of frontend
          development to transform the digital landscape, and I'm dedicated to
          shaping the future of the web.
        </p>
        <p>
          From responsive layouts to elegant typography, from stunning visuals
          to smooth performance, I meticulously craft every detail to ensure an
          unforgettable user experience. By harnessing the latest frontend
          technologies and staying ahead of industry trends, I stay at the
          forefront of innovation, constantly evolving to deliver cutting-edge
          solutions.
        </p>
        <p>
          Join me on this journey of creativity and innovation as we shape the
          digital world, one line of code at a time. Let's collaborate and turn
          your vision into an extraordinary reality.
        </p>
      </div>
    </div>
  );
};

export default Profile;
