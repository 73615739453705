import React from 'react';
import { NavLink } from 'react-router-dom';
import { sun } from '../../../assets/icons';

const navItems = [
  { to: '/about', label: 'About' },
  { to: '/projects', label: 'Projects' },
  { to: '/articles', label: 'Articles' },
  { to: '/uses', label: 'Uses' },
];

const Navbar = () => {
  // const [lightMode, setLightMode] = useState(sun);

  return (
    <div className='flex justify-between items-center h-16'>
      <p className='text-teal font-normal text-lg'>&lt;Andrew /&gt;</p>
      <div className='flex items-center gap-8 border-0 border-zinc rounded-3xl p-2 h-12 w-96 justify-center font-medium bg-zinc-800 text-sm'>
        {navItems.map(({ to, label }) => (
          <NavLink key={to} to={to} className='text-white hover:text-teal'>
            {({ isActive }) => (
              <span
                className={
                  isActive
                    ? 'text-teal hover:text-white'
                    : 'text-zinc-200 hover:text-teal'
                }
              >
                {label}
              </span>
            )}
          </NavLink>
        ))}
      </div>
      <div
        className='h-12 w-12 rounded-full justify-center flex items-center bg-zinc-800 cursor-pointer'
        // onClick={setLightMode(moon)}
      >
        <img src={sun} alt='sun' />
      </div>
    </div>
  );
};

export default Navbar;
