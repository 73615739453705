import React from 'react';
import HomeView from './HomeView';
import { Wrapper } from '../../components/modules/Wrapper';

export const HomeContainer = () => {
  return (
    <Wrapper>
      <HomeView />
    </Wrapper>
  );
};
