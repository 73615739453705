const Hero = () => {
  return (
    <div className='text-white w-[60%]'>
      <h2 className='text-5xl font-bold tracking-tight text-zinc-100 leading-[60px] mt-6'>
        Software I use, gadgets I love, and other things I recommend.
      </h2>
      <p className='mt-6 text-base text-zinc-400 leading-7'>
        I get asked a lot about the things I use to build software, stay
        productive, or buy to fool myself into thinking I’m being productive
        when I’m really just procrastinating. Here’s a big list of all of my
        favorite stuff.
      </p>
    </div>
  );
};

export default Hero;
