import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import RouterConfig from './navigation/RouterConfig';

function App() {
  return (
    <div className='App font-poppins bg-black xl:px-36 px-8 sm:px-12'>
      <BrowserRouter>
        <RouterConfig />
      </BrowserRouter>
    </div>
  );
}
export default App;
