import React from 'react';
import { Content, Hero } from '../../components/layout/Home';

const HomeView = () => {
  return (
    <div className=''>
      <Hero />
      <Content />
    </div>
  );
};

export default HomeView;
