import React from 'react';
import { About } from '../../components/layout/About';

const AboutView = () => {
  return (
    <div>
      <About />
    </div>
  );
};

export default AboutView;
