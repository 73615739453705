import React from 'react';
import { briefcase } from '../../../assets/icons';

const workplaces = [
  {
    id: 1,
    logo: '',
    company: 'United States of Africa',
    position: 'Senior Frontend Engineer',
    duration: '2023 - Present',
  },
  {
    id: 2,
    logo: '',
    company: 'United States of Africa',
    position: 'Frontend Developer',
    duration: '',
  },
  {
    id: 3,
    logo: '',
    company: 'Powerblaze Technologies',
    position: 'Frontend Developer',
    duration: '',
  },
  {
    id: 4,
    logo: '',
    company: 'Freelance',
    position: 'Frontend Developer',
    duration: '',
  },
];

const Work = () => {
  return (
    <div className='w-[400px] p-6 border-[0.5px] border-[#333333] rounded-2xl'>
      <div className='flex gap-4'>
        <img src={briefcase} alt='briefcase' />
        <h3 className='text-zinc-100 font-semibold'>Work</h3>
      </div>
      <div className='flex flex-col gap-4 mt-6'>
        {workplaces.map((item) => (
          <div key={item.id} className='flex justify-between'>
            <div className='flex items-center gap-4'>
              <img src={item.logo} alt='' />
              <div className='flex flex-col gap-2'>
                <p className='text-zinc-100 font-semibold text-sm'>
                  {item.company}
                </p>
                <p className='text-zinc-400 text-sm'>{item.position}</p>
              </div>
            </div>
            <p className='text-zinc-300 text-sm'>{item.duration}</p>
          </div>
        ))}
      </div>
      <button className='bg-zinc-800 rounded-md p-2 w-full mt-12 text-zinc-100 hover:bg-zinc-800'>
        Download CV
      </button>
    </div>
  );
};

export default Work;
