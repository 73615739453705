import React from 'react';
import { Link } from 'react-router-dom';

const navItems = [
  { to: '/about', label: 'About' },
  { to: '/projects', label: 'Projects' },
  { to: '/articles', label: 'Articles' },
  { to: '/uses', label: 'Uses' },
];

const Footer = () => {
  return (
    <div className='border-t-[0.5px] border-t-[#333333] py-8'>
      <div className='flex justify-between items-center h-16  px-8 xl:px-20'>
        <div className='flex items-center gap-8 pl-4 justify-center text-base'>
          {navItems.map(({ to, label }) => (
            <Link key={to} to={to} className='text-white hover:text-teal'>
              <span className='text-white hover:text-teal'>{label}</span>
            </Link>
          ))}
        </div>

        <p className='text-zinc-300'>
          &copy; {new Date().getFullYear()} Andrew Ogbodo. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
