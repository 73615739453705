import React from 'react';
import { Route, Routes } from 'react-router-dom';
// import { Home, About, Articles, ErrorPage, Projects, Uses } from 'pages';
import { HOME, ABOUT, ARTICLES, PROJECTS, USES } from './CONSTANTS';
import { Home, About, Articles, Error, Projects, Uses } from '../pages';

function RouterConfig() {
  return (
    <div>
      <Routes>
        <Route exact path={HOME} element={<Home />} />
        <Route exact path={ABOUT} element={<About />} />
        <Route path={ARTICLES} element={<Articles />} />
        <Route path={PROJECTS} element={<Projects />} />
        <Route path={USES} element={<Uses />} />

        {/*************************404************************************** */}

        {/* List All 404 routes here */}
        <Route path='*' element={<Error />} />
      </Routes>
    </div>
  );
}

export default RouterConfig;
