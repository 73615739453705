import React from 'react';
import Hero from './Hero';
import Links from './Links';

const Projects = () => {
  return (
    <div>
      <Hero />
      <Links />
    </div>
  );
};

export default Projects;
