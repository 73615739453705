import React from 'react';
import { message } from '../../../assets/icons';

const Subscribe = () => {
  return (
    <div className='w-[400px] p-6 border-[0.5px] border-[#333333] rounded-2xl'>
      <div className='flex items-center gap-4'>
        <img src={message} alt='message' />
        <h3 className='text-zinc-100 text-base font-semibold'>
          Stay up to date
        </h3>
      </div>
      <p className='text-zinc-400 text-sm mt-4 leading-7'>
        Get notified when I publish something new, and unsubscribe at any time.
      </p>
      <div className='flex justify-between gap-2 mt-6'>
        <input
          type='text'
          placeholder='Email address'
          className='bg-zinc-800 placeholder:text-zinc-300 placeholder:text-sm rounded-md border-[1px] border-[#333333] p-2 w-full'
        />
        <button className='p-2 text-zinc-100 bg-[#52525B] text-sm rounded-md'>
          Join
        </button>
      </div>
    </div>
  );
};

export default Subscribe;
