import React from 'react';
import { avatar } from '../../../assets/images';
import { Github, Linkedin, Message, Twitter } from '../../modules/Icons';

const socialIcons = [
  {
    id: 1,
    url: 'https://github.com/dreiOX',
    Icon: Github,
    label: 'Github',
  },
  {
    id: 2,
    url: 'https://linkedin.com/in/andrew-ogbodo',
    Icon: Linkedin,
    label: 'Linkedin',
  },
  {
    id: 3,
    url: 'https://twitter.com/dreicode',
    Icon: Twitter,
    label: 'Twitter',
  },
];

const Socials = () => {
  return (
    <div className='w-[45%]'>
      <img src={avatar} alt='avatar' />
      <div className='flex flex-col gap-4 mt-12'>
        {socialIcons.map(({ url, Icon, label, id }) => (
          <a
            href={url}
            key={id}
            className='flex items-center gap-4 text-sm font-medium text-zinc-100 group hover:text-teal cursor-pointer transition-all duration-200'
          >
            <div className='hover:text-teal'>
              <Icon />
            </div>
            <p>Follow me on {label}</p>
          </a>
        ))}
      </div>
      <div className='text-zinc-100 text-sm border-t-[0.5px] border-t-[#333333] mt-20 py-12 '>
        <div className='hover:text-teal transition-all duration-200 flex items-center gap-4'>
          <Message />
          <a href='mailto:ogbodoandrew@gmail.com'>ogbodoandrew@gmail.com</a>
        </div>
      </div>
    </div>
  );
};

export default Socials;
