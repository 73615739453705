import React from 'react';
import { Link } from '../../modules/Icons';

const projects = [
  {
    id: 1,
    icon: '',
    title: 'Planetaria',
    description:
      'Creating technology to empower civilians to explore space on their own terms',
    link: '',
  },
  {
    id: 2,
    icon: '',
    title: 'Planetaria',
    description:
      'Creating technology to empower civilians to explore space on their own terms',
    link: '',
  },
  {
    id: 3,
    icon: '',
    title: 'Planetaria',
    description:
      'Creating technology to empower civilians to explore space on their own terms',
    link: '',
  },
  {
    id: 4,
    icon: '',
    title: 'Planetaria',
    description:
      'Creating technology to empower civilians to explore space on their own terms',
    link: '',
  },
  {
    id: 5,
    icon: '',
    title: 'Planetaria',
    description:
      'Creating technology to empower civilians to explore space on their own terms',
    link: '',
  },
  {
    id: 6,
    icon: '',
    title: 'Planetaria',
    description:
      'Creating technology to empower civilians to explore space on their own terms',
    link: '',
  },
];

const Links = () => {
  return (
    <div className='grid grid-cols-3 gap-4'>
      {projects.map((item) => (
        <a
          href={item.link}
          key={item.id}
          className='hover:border-1 hover:border-zinc hover:rounded-2xl hover:bg-zinc-800 p-4 transition-all duration-300 group'
        >
          <img src={item.icon} alt={item.title} />
          <h3 className='text-white text-base font-semibold mt-6'>
            {item.title}
          </h3>
          <p className='text-zinc-400 text-sm mt-4'>{item.description}</p>
          <span className='flex items-center gap-2 group-hover:text-teal text-white hover:text-teal text-xs mt-6 transition-all duration-300'>
            <Link />
            <a href={item.link} className=''>
              github.com
            </a>
          </span>
        </a>
      ))}
    </div>
  );
};

export default Links;
