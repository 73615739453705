import React from 'react';
import { Navbar } from '../Navbar';
import { Footer } from '../Footer';

const Wrapper = ({ children }) => {
  return (
    <div className='bg-gray-600 h-full flex flex-col justify-between pt-8'>
      <section className='px-8 xl:px-20 '>
        <Navbar />
      </section>
      <section className='px-8 xl:px-20 py-16'>{children}</section>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default Wrapper;
