import React from 'react';
import ArticlesView from './ArticlesView';
import { Wrapper } from '../../components/modules/Wrapper';

export const ArticlesContainer = () => {
  return (
    <Wrapper>
      <ArticlesView />
    </Wrapper>
  );
};
