import React from 'react';
import { Articles } from '../../components/layout/Articles';

const ArticlesView = () => {
  return (
    <div>
      <Articles />
    </div>
  );
};

export default ArticlesView;
