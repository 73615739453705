import React from 'react';
import ProjectsView from './ProjectsView';
import { Wrapper } from '../../components/modules/Wrapper';

export const ProjectsContainer = () => {
  return (
    <Wrapper>
      <ProjectsView />
    </Wrapper>
  );
};
